.background-container-1 {
	background-image: url("../../../assets/images/about/About2.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	position: relative;
	top: 0;
	left: 0;
	margin-top: 100px;
	height: fit-content;
	animation: aboutFadeIn1 0.3s ease-in-out;
}

.background-container-2 {
	background-image: url("../../../assets/images/about/About1.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100vw;
	position: relative;
	top: 0;
	left: 0;
	height: fit-content;
	animation: aboutFadeIn2 0.5s ease-in-out;
}

.block-container {
	display: flex;
	flex-flow: column;
	/* min-height: 100vh; */
	height: fit-content;
	margin-left: 10vw;
	width: 900px;
	background-color: white;
}

.title {
	float: left;
	text-align: left;
	color: rgb(214, 182, 38);
	font-size: 38px;
	font-weight: 100;
	line-height: 1.3;
	padding-left: 5vw;
	padding-top: 20px;
}

.subtitle {
	float: left;
	text-align: left;
	color: rgb(214, 182, 38);
	font-size: 38px;
	font-weight: 100;
	line-height: 1.3;
	padding-left: 5vw;
}

.description {
	font-size: 18px;
	color: rgb(82, 63, 39);
	font-weight: 300;
	line-height: 1.5;
	white-space: pre-line;
	padding-right: 5vw;
	padding-left: 5vw;
	padding-top: 20px;
	text-align: left;
}

.headline {
	font-size: 18px;
	color: rgb(82, 63, 39);
	font-weight: 500;
	line-height: 1.5;
	white-space: pre-line;
	padding-right: 5vw;
	padding-left: 5vw;
	padding-top: 20px;
	text-align: left;
}

.text {
	position: absolute;
	margin-left: -600px;
	margin-top: 150px;
	padding-bottom: 50px;
	width: 700px;
	height: 600px;
	font-size: 20px;
	color: rgb(82, 63, 39);
	font-weight: 100;
	line-height: 1.5;
	resize: none;
	border: none;
	white-space: pre-line;
}

.signatureDiv {
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 25px;
	padding-right: 25px;
}

.signatureDiv>p {
	font-size: 20px;
	color: rgb(82, 63, 39);
	margin-left: 25px;
}

.hashtags {
	margin-top: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
}

.socialmediaDiv {
	display: flex;
	justify-content: center;
	align-items: center;
}

.socialmediaDiv>* {
	margin: 25px 20px 25px 20px
}

.socialmediaDiv>*:hover {
	transform: scale(1.1);
	color: #B69137;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

@media screen and (max-width: 1020px) {


	.block-container {
		margin-left: 0;
		width: 100vw;
		align-items: center;
	}

	.background-container-1 {
		margin-bottom: 25px;
	}

	.background-container-2 {
		margin-bottom: 25px;
	}

	.mobile-image-1 {
		background-image: url("../../../assets/images/about/About2.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 200px;
		width: 100vw;
		margin: 20px 0px 20px 0;
	}

	.mobile-image-2 {
		background-image: url("../../../assets/images/about/About1.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 200px;
		width: 100vw;
		margin: 20px 0px 20px 0;
	}

	.title {
		font-size: 30px;
		padding-left: 0px;
		text-align: center;
	}

	.subtitle {
		font-size: 30px;
		padding-left: 0px;
		text-align: center;
	}

	.socialmediaDiv>img {
		margin: 25px 0px 25px 0px
	}

	.socialmediaDiv {
		display: flex;
		width: 100%;
		justify-content: space-evenly;
		align-items: center;
	}
}

@keyframes aboutFadeIn1 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes aboutFadeIn2 {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}