.reservation-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 100%;
    margin: 5px;
}

.reservation-review-div {
    width: 45%;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.form-info-title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.form-title {
    font-size: 22px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
    margin: 5px;
}

.form-subtitle {
    color: var(--text-dark);
}

.reservation-info-review-div {
    width: 100%;
}

.reservation-form-label-title {
    padding-bottom: 8px;
    font-size: 17px;
    letter-spacing: 2px;
    color: rgb(97, 99, 79);
    padding-left: 15px;
    text-transform: uppercase;
}

.reservation-form-text-area {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 300px;
    border: none;
    outline: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    padding: 5px 10px;
}

.reservation-form-input-fields {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
    position: relative;
}

.reservation-form-input-fields > * {
    margin-bottom: 20px;
}

.message-label {
    margin-bottom: 10px;
}

.form-required-text {
    position: absolute;
    bottom: -10px;
    margin: 0;
    color: #61634F;
    font-size: 12px;
    letter-spacing: 2px;
}

@media screen and (max-width: 960px) {
    .reservation-form-container {
        width: 100%;
    }

    .reservation-form-input-fields {
        width: 90%;
    }

    .form-info-title-div {
        margin-bottom: 20px;
    }
}