.loja-container {
    width: auto;
    min-height: 81vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    padding-top: 100px;
}

.loja-tapecaria,
.loja-decoraçao,
.loja-iluminaria,
.loja-mobiliario {
    width: 49%;
    height: auto;
    min-width: 400px;
    min-height: 380px;
    margin: 5px; 
    display: inherit;
    justify-content: inherit;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
}

.title-container {
    position: absolute;
    width: inherit;
    height: 380px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.loja-mobiliario:hover .plus-sign,
.loja-decoraçao:hover .plus-sign,
.loja-iluminaria:hover .plus-sign,
.loja-tapecaria:hover .plus-sign {
    transform: rotate(180deg);
    transition: all 0.5s ease-out;
}

.title-container:hover .title-loja > p {
    color: #B39E2D;
    transition: all 0.2s ease-out;
}

.title-loja {
    background-color: white;
    width: 50%;
    height: 50px;
    margin-bottom: -5px;
}

.title-loja > p {
    font-size: 22px;
    letter-spacing: 3px;
    text-align: left;
    line-height: 50px;
    font-weight: 400;
    color: #62656D;
    padding-left: 10px;
}

.plus-sign{
    background-image: url("../../../assets/images/loja/+.png");
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: center;
    position: relative;
    width: 15%;
    height: 30%;
}

.loja-mobiliario {
    background-image: url("../../../assets/images/loja/CATEGORIAS-36.jpg");
}

.loja-iluminaria {
    background-image: url("../../../assets/images/loja/CATEGORIAS-32.jpg");

}

.loja-tapecaria {
    background-image: url("../../../assets/images/loja/CATEGORIAS-34.jpg");

}

.loja-decoraçao {
    background-image: url("../../../assets/images/loja/CATEGORIAS-33.jpg");
}


@media screen and (max-width: 960px) {
 
    .loja-container {
        width: 96vw;
        margin: 5px 10px 5px 10px;
    }

    .title-container {
        height: 240px;
        width: 110%;
        margin-right: 25px;
    }

    .plus-sign {
        height: 25%;
        background-size: 300%;
    }

    .loja-tapecaria,
    .loja-decoraçao,
    .loja-iluminaria,
    .loja-mobiliario {
        width: 100%;
        height: auto;
        min-width: 0px;
        min-height: 240px;
    }

}
