.footer-container {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.termsAndConditions:hover {
    color: blue;
    cursor: pointer;
}

.termsAndConditions {
    letter-spacing: 2px;
    font-size: 14px;
    color: rgb(88, 86, 69);
    padding-right: 30px;
    text-align: center;
}

.left-side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    max-width: 1000px;
    width: 80%;
}

.right-footer-bar {
    margin-top: 15px;
    border-style: none;
}

.left-footer-bar {
    margin-left: 30px;
    border-style: none;
}

@media screen and (max-width: 960px) {

    .footer-container {
        flex-direction: column;
        justify-content: center;
        height: 150px;
    }

    .left-side {
        flex-direction: column;
        justify-content: space-evenly;
        width: 100vw;
        height: 80%;
    }

    .right-footer-bar {
        width: 350px;
        margin-top: 20px;
    }

    .left-footer-bar {
        margin-top: 20px;
        margin-left: 10px;
    }

}