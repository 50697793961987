  .svg-map {
  	margin-top: -15%;
  	width: 80%;
  	height: auto;
  }

  [class^="svg-map__location--pin"] {
  	/*selecting all pins*/
  	fill: #ffffff;
  	outline: 0;
  }


  [class^="svg-map__location--names"] {
  	/*selecting all names*/
  	fill: #ffffff;
  	outline: 0;
  }

  [class^="svg-map__location--id"] {
  	/*selecting all districts*/
  	fill: #C8B852;
  	cursor: pointer;
  	outline: 0;
  	stroke: white;
  	stroke-width: 2;
  	stroke-linecap: round;
  	stroke-linejoin: round;
  	outline: 0;
  }

  .svg-map__location--pin--hidden {
  	fill: none;
  }

  .svg-map__location--null {
  	fill: #C8B852;
  	outline: 0;
  	stroke: white;
  	stroke-width: 2;
  	stroke-linecap: round;
  	stroke-linejoin: round;
  	outline: 0;
  }

  [class^="svg-map__location--id"]:hover,
  [class^="svg-map__location--id"]:focus {
  	fill: #AB9F45;
  	cursor: pointer;
  	outline: 0;
  	filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.5));
  	transform: scale(1.01);
  	-webkit-transform: scale(1.01);
  	-webkit-transform-origin: 50% 50%;
  	-webkit-transition: .2s;
  	transform-origin: 50% 50%;
  	transform-box: fill-box;
  	transition: all 0.2s ease-out;
  }

  .background-container {
  	background-image: url("../../../assets/images/espaços/EspaçosBackground.jpg");
  	background-repeat: no-repeat;
  	background-size: cover;
  	background-position: center;
  	width: 100vw;
  	height: fit-content;
  	position: relative;
  	top: 0;
  	left: 0;
  	animation: espaçosFadeIn 0.3s ease-in-out;
  }

  .container {
  	display: flex;
  	flex-direction: row-reverse;
  	height: inherit;
  	padding-top: 100px;
  }

  .left-container {
  	/* border: 2px solid cadetblue; */
  	width: 50%;
  	height: inherit;
  	display: flex;
  	flex-direction: column;
  	margin: 70px 0px 0px 20px;
  }

  .right-container {
  	display: flex;
  	justify-content: center;
  	height: 100vh;
  	width: 50%;
  	margin: 70px 20px 0px 5px;
  	animation: mapAnimation 0.5s ease-in-out forwards;
  }

  .text-container {
  	display: flex;
  	flex-direction: column;
  	width: 100%;
  	height: fit-content;
  	text-align: left;
  }


  .description-espaços {
  	font-size: 18px;
  	color: rgb(104, 109, 107);
  	font-weight: 300;
  	line-height: 1.5;
  	padding-right: 5vw;
  	padding-left: 2vw;
  	padding-top: 10px;
  	text-align: left;
  }

  .title-espaços {
  	float: left;
  	text-align: left;
  	color: rgb(97, 99, 79);
  	font-size: 38px;
  	font-weight: 200;
  	line-height: 1.3;
  	padding-left: 2vw;
  	padding-top: 3vh;
  	letter-spacing: 2px;
  	width: 60%;
  }

  .cards-espaços {
  	flex-grow: 2;
  	/* border: 2px solid darkblue; */
  	width: 100%;
  	display: flex;
  	flex-direction: column;
  	justify-content: flex-start;
  	align-items: center;
  	padding-top: 25px;
  }

  .accordion-item {
  	margin: 5px;
  	width: 95%;
  	transform: translateY(400px);
  	opacity: 0;
  	animation: accordionAnimation 0.6s ease-in-out forwards;
  }

  .accordion-title {
  	display: flex;
  	flex-direction: row;
  	justify-content: space-between;
  	cursor: pointer;
  	background-color: rgb(223, 224, 220);
  	font-size: 17px;
  	font-weight: bold;
  	color: rgb(97, 99, 79);
  	letter-spacing: 2px;
  }

  .accordion-title:hover,
  .symbol:hover {
  	color: rgb(200, 184, 82);
  	transition: all 0.2s ease-out;
  }

  .accordion-title:hover .symbol {
  	transform: rotate(360deg);
  	transition: all 0.7s ease-out;
  }

  .accordion-content {
  	background-color: rgb(223, 224, 220);
  }

  .hide-div {
  	height: 0;
  }

  .show-div {
  	height: 16vw;
  	transition: all 0.5s ease-in-out;
  }

  .hide-div .content-container {
  	height: 0px;
  }

  .show-div .accordion-content {
  	height: inherit;
  }

  .hide-div .content-p,
  .hide-div .content-p-1 {
  	font-size: 0;
  }

  .show-div .content-p,
  .show-div .content-p-1 {
  	margin-top: -20px;
  	font-size: 15px;
  	color: rgb(97, 99, 79);
  	transition: all 0.1s ease-in-out;
  }

  .hide-div .content-image {
  	opacity: 0.0;
  	height: 0%;
  }

  .show-div .content-image {
  	opacity: 1;

  	transition: all 1s ease-in;
  }

  .hide-div .content-description {
  	font-size: 0;
  }

  .show-div .content-description {
  	display: flex;
  	height: 75%;
  	/* line-height: 25px; */
  	line-height: 1.5vw;
  	text-align: left;
  	/* font-size: 16px; */
  	font-size: 1vw;
  	font-weight: 500;
  	color: rgb(97, 99, 79);
  	margin-left: 15px;
  	transition: all 0.2s ease-in-out;
  	white-space: pre-line;
  }

  .hide-div .content-buttons {
  	height: 0%;
  	opacity: 0.0;
  }

  .show-div .content-buttons {
  	height: 25%;
  	opacity: 1;
  	transition: all 0.5s ease-in;
  }

  .div-title {
  	align-self: center;
  	margin-left: 30px;
  	width: 100%;
  }

  .symbol {
  	margin-bottom: 25px;
  	margin-right: 10px;
  	font-size: 38px;
  	font-weight: 100;
  }

  .content-container {
  	display: flex;
  	flex-direction: row;

  }

  .content-left {
  	margin: 15px 5px 25px 30px;
  	display: flex;
  	width: 275%;
  	flex-direction: column;
  }

  .content-image {
  	width: 100%;
  	max-width: 450px;
  	margin-top: 12px;
  }

  .content-p {
  	margin-top: -28px;
  	color: rgb(97, 99, 79);
  }

  .content-p-1 {
  	margin-top: -20px;
  	color: rgb(97, 99, 79);
  }

  .content-right {
  	margin: 15px 15px 30px 15px;
  	display: flex;
  	flex-direction: column;
  	min-width: 40%;
  }

  .content-description {
  	display: flex;
  	height: 75%;
  	line-height: 25px;
  	text-align: left;
  	font-size: 16px;
  	font-weight: 500;
  	color: rgb(97, 99, 79);
  	margin-left: 15px;
  }

  .content-buttons {
  	display: flex;
  	height: 25%;
  	justify-content: space-evenly;
  }

  .content-buttons>* {
  	width: 25%;
  	height: auto;
  	background-size: contain;
  	background-repeat: no-repeat;
  	background-position: center;
  }

  .button1 {
  	background-image: url("../../../assets/images/espaços/expandable/ÍCONES-54.png");
  }

  .content-buttons>*:hover {
  	transform: scale(1.1);
  	transition: all 0.2s ease-out;
  }

  .button2 {
  	background-image: url("../../../assets/images/espaços/expandable/ÍCONES-55.png");
  }

  .button3 {
  	background-image: url("../../../assets/images/espaços/expandable/ÍCONES-56.png");
  }

  .mobile-map {
  	display: none;
  }

  @media screen and (max-width: 960px) {

  	.background-container {
  		min-height: 110vh;
  		height: fit-content;
  	}

  	.container {
  		flex-direction: column;
  	}

  	.show-div .content-description {
  		font-size: 16px;
  		line-height: 25px;
  	}

  	.show-div {
  		height: fit-content;
  	}

  	.right-container {

  		display: none;
  	}

  	.mobile-map {
  		width: 100%;
  		display: block;
  	}

  	.svg-map {
  		transform: scale(1);
  		margin-top: 10px;
  		width: 100%;
  		height: 100%;
  	}

  	.cards-espaços {
  		z-index: 20;
  	}

  	.left-container {
  		width: 95vw;
  		height: auto;
  		margin: 10px;
  	}

  	.div-title {
  		margin-left: 20px;
  	}

  	.text-container {
  		height: fit-content;
  	}

  	.content-container {
  		flex-direction: column;
  		height: fit-content;
  	}

  	.content-right {
  		height: 220px;
  		margin: 10px;
  	}

  	.accordion-title {
  		padding: 0;
  	}

  	.content-left {
  		height: 100%;
  		margin: 15px 2px 5px 20px;
  		width: 100%;
  	}

  	.show-div .content-p {
  		margin-top: -20px
  	}

  	.content-p-1 {
  		margin-top: -10px;
  	}

  	.content-buttons {
  		height: 35% !important;
  	}

  	.content-image {
  		width: 80vw;
  	}

  	.title-espaços {
  		width: 100%;
  	}
  }

  @keyframes espaçosFadeIn {
  	0% {
  		opacity: 0;
  	}

  	100% {
  		opacity: 100%;
  	}
  }

  @keyframes mapAnimation {
  	0% {
  		opacity: 0;
  	}

  	100% {
  		opacity: 1;
  	}
  }

  @keyframes accordionAnimation {
  	0% {
  		transform: translateY(400px);
  		opacity: 0;
  	}

  	100% {
  		transform: translateY(0);
  		opacity: 1;
  	}
  }

