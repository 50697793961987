.notFound-container {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/images/contacts/FUNDO_CONTACTOS.jpg');
    padding-top: 100px;
}

.inner-div {
    height: 50%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.not-found-title {
    font-size: 168px;
    font-weight: bolder;
    color: white;

}

.not-found-text {
    font-weight: 700;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-top: 0px;
}

.return-btn {
    border-radius: 5px;
    background-color: white;
    margin-top: 30px;
    text-decoration: none;
    text-align: center;
    color: black;
    height: 30px;
    width: 150px;
    line-height: 30px;
}

.return-btn:hover {
    width: 160px;
    transition: all 0.2s ease-out;
}