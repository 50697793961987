* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Libre Franklin", sans-serif;
  /* overflow-x: hidden; */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

:root {
  --text-dark: #61634f;
  --text-light: #d2bb53;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border: 2.5px solid transparent;
  border-radius: 20px;
  background-color: rgb(180, 180, 180);
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #262936;
}