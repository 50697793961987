.seats-main-container {
    height: fit-content;
    min-height: 425px;
    width: 100%;
    margin: 25px 0;
    display: flex;
}

.seats-info {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 50px 25px;
}

.seats-info-title-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.seats-title {
    font-size: 22px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
    margin: 5px;
}

.seats-subtitle {
    color: var(--text-dark);
}

.seats-persons-display {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.seats-pick-icons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
}

.seats-bottom-text {
    position: absolute;
    bottom: 5px;
    width: 95%;
    text-align: center;
    margin: 5px 0;
    font-size: 15px;
    font-style: italic;
    color: var(--text-dark);
}

.seats-blueprint {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}