.tnc-background-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.5s ease-out;
}

.tnc-popup {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
}

.tnc-navigation-div {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.tnc-navigation-div>p {
    padding-left: 30px;
    color: rgb(97, 99, 79);
    font-size: 28px;
    font-weight: 200;
    line-height: 1.3;
    letter-spacing: 2px;
}

.tnc-btn-div {
    display: flex;
    width: 20%;
    height: 85%;
    justify-content: flex-end;
    align-items: center;
}

.content-div {
    width: 98%;
    padding: 0 20px;
    height: 93%;
    margin: 25px;
    overflow-y: scroll;
    scrollbar-width: auto;
}

.content-div::-webkit-scrollbar {
    width: 10px;
}

.tnc-btn-div>* {
    cursor: pointer;
    margin-right: 30px;
}


.fa-window-close {
    color: rgb(97, 99, 79);
    font-size: 2.5em;
}

.react-pdf__Page {
    display: flex;
    justify-content: center;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
    max-width: 1000px;
}

/* --------------- footer dialog ------------- */


@media screen and (max-width: 960px) {

    .tnc-navigation-div {
        height: 25%;
    }

    .tnc-navigation-div>p {
        font-size: 18px;
    }

    .tnc-popup {
        width: 100%;
        height: 100%;
    }

    .react-pdf__Page__canvas {
        width: 100% !important;
        height: 100% !important;
    }
}