.feedback-form-container {
    margin: 0px 11% 25px 11%;
    min-height: 495px;
    height: fit-content;
}

.feedback-form-subcontainer {
    height: inherit;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.feedback-text-area {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    height: fit-content;
    max-height: 500px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
    padding-top: 5px
}

.feedback-text-area:focus,
.feedback-text-area:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.feedback-text-areaerror {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 500px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;;
    border: 1px solid rgb(170, 38, 38);
}

.feedback-text-areaerror:focus,
.feedback-text-areaerror:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.feedback-input {
    width: 100%;
    height: 40px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
}

.feedback-input:focus,
.feedback-input:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.feedback-label-title {
    padding-bottom: 8px;
    font-size: 17px;
    letter-spacing: 2px;
    color: rgb(97, 99, 79);
    padding-left: 15px;
}

.feedback-label-title-2 {
    padding-bottom: 8px;
    font-size: 17px;
    letter-spacing: 2px;
    text-align: center;
    color: rgb(97, 99, 79);
}

.feedback-inputerror {
    width: 100%;
    height: 40px;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;;
    border: 1px solid rgb(170, 38, 38);
    border-right: 15px solid rgb(170, 38, 38);
}

.feedback-inputerror:focus,
.feedback-inputerror:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.feedback-phone-email-fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.feedback-phone-email-fields > label {
    width: 48%;
}

.feedback-submitButton-Loading-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.feedback-loading-symbol {
    z-index: 99;
    margin-left: -55px;
    height: 50px;
    width: 50px;
    fill:#ffffff;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-opacity: 1
}

.feedback-submitButton-Loading-div > button {
    max-width: 300px;
    width:250px;
    height: 50px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: center;
    font-size: 17px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.feedback-submitButton-Loading-div > button:disabled {
    background-color: grey;
    cursor:default;
}

.feedback-submitButton-Loading-div > button:disabled:hover {
    transform: scale(1);
    font-size: 17px;
}

.feedback-submitButton-Loading-div > button:hover {
    transform: scale(1.02);
    transition: all 0.3s ease-out;
    font-size: 19px;
}

.ratingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}

@media screen and (max-width: 960px) {

    .feedback-form-container {
        margin: -5px 35px 10px 35px;
    }

    .feedback-loading-symbol {
        margin-left: -60px;
    }

    .feedback-submitButton-Loading-div > button {
        text-align: center;
        text-indent: 0;
        width: 100%;
    }

}
