
.upper-text-container {
    min-height: 200px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 50px;
    padding-top: 100px;
}

.upper-text {
    width: 50%;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    color: #64634F;
    font-weight: 400;
    white-space: pre-line;
}

.empresas {
    min-height: 150px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 20px 25px 25px 50px
}


.individuais {
    min-height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 25px;
    margin-right: 25px;
}

.institucionais {
    min-height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 25px 35px 50px
}

.cards-title {
    margin-left: 50px;
    font-weight: 400;
    letter-spacing: 1px;
}

.institucionais > img {
    margin: 25px;
}

.individual-card {
    margin: 25px;
}

.sec-background {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-info {
    color: rgb(255, 255, 255);
    text-align: center;
    z-index: -1;
}

.card:hover {
    border-radius: 5%;
    transition: all 0.4s ease-out;
    cursor: pointer;
}

.card:hover .sec-background {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5%;
    transition: all 0.4s ease-out;
}

.card:hover .card-info {
    z-index: 1;
}

.card-info > p {
    font-size: 14px;
    letter-spacing: 2px;
}

.link {
    text-decoration: none;
}

.institucionais > img:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: all 0.4s ease-out;
}

.empresas > img {
    margin: 5px 5px 5px 5px;
}

.empresas > img:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: all 0.4s ease-out;
}

.image-loading-icon {
    height: 70px;
    width: 70px;
    fill:#B39E2D;
    stroke: #B39E2D;
    stroke-width: 2;
    stroke-opacity: 1
}

@media screen and (max-width: 960px) {

    .upper-text {
        width: 100%;
        margin-bottom: 20px;
    }

    .upper-text-container {
        margin: 25px;
    }


    .sec-background {
        height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 100%);
        border-radius: 5%;
        z-index: 0;
    }

    .card-info {
        z-index: 1;
        top: -80px
    }

    .card {
        border-radius: 5%;
    }
    
    .card:hover {
        transition: all 0.4s ease-out;
    }

    .card:hover .sec-background {
        background-color: rgba(255, 255, 255, 0);
        border-radius: 5%;
        transition: all 0.4s ease-out;
    } 

    .institucionais {
        margin: 0px 0px 30px 0px;
        justify-content: center;
    }

    .cards-title {
        text-align: center;
        margin-left: 0;
        font-size: 22px;
    }

    .individuais {
        margin-left: 0;
        margin-right: 0;
    }

    .individual-card {
        margin-right: 0;
        margin-left: 0;
    }

    @media screen and (max-width: 500px) {
        .institucionais > img {
            width: 70%;
            height: auto;
        }
    }
}