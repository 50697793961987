.navbar.show {
	background: white;
	height: 100px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: fixed;
	top: 0;
	z-index: 900;
	box-shadow: 0px 2px 6px 0 rgb(0 0 0 / 50%);
	transition: top 0.3s ease-in-out;
	animation: navbarFadeIn 0.5s ease-in-out;
}

.navbar.hide {
	background: white;
	height: 100px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	/* position: sticky; Com overflow-x hidden, sticky não acompanha o scroll */
	position: fixed;
	top: -100px;
	z-index: 900;
	transition: all 0.3s ease-in-out;
}

.navbar-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100px;
	max-width: 1500px;
}

.navbar-logos {
	position: absolute;
	left: 0;
	height: inherit;
	max-width: 400px;
	width: 30vw;
	display: flex;
	justify-content: space-around;
	align-items: center;

}

.logo-2 {
	width: auto;
	height: 35px;
	cursor: pointer;
	border-style: none;
}

.logo {
	width: auto;
	height: 65px;
	border-style: none;
}

.logo:hover,
.logo-2:hover {
	transform: scale(1.02);
	transition: all 0.4s ease-out;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-gap: 0px;
	list-style: none;
	text-align: center;
	width: 70vw;
	justify-content: flex-end;
	position: absolute;
	right: 0;
}

.nav-item {
	height: 100px;
}

.active-link {
	border-bottom: 4px solid rgb(179, 158, 45);
	font-weight: bold;
	color: rgb(203, 184, 82) !important;
}

.nav-links-reservar {
	background: rgb(179, 158, 45);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	width: 100%;
	min-width: 150px;
	font-size: 14px;
	letter-spacing: 4px;
	font-weight: bold;
}

.active-reservar {
	border-bottom: 4px solid white;
	font-weight: bold;
}

.nav-links-reservar:hover {
	border-bottom: 4px solid white;
	transition: all 0.2s ease-out;
}

.nav-links {
	background: rgb(238, 239, 237);
	color: rgb(88, 86, 69);
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	width: 100%;
	min-width: 150px;
	font-size: 15px;
	letter-spacing: 4px;
}

.nav-links:hover {
	border-bottom: 4px solid rgb(179, 158, 45);
	color: rgb(203, 187, 82);
	transition: all 0.2s ease-out;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

.navbar-language-changer {
	background: rgb(238, 239, 237);
	color: rgb(88, 86, 69);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem 1rem;
	height: 100%;
	width: 100%;
	min-width: 150px;
	font-size: 15px;
	gap: 5px;
	letter-spacing: 4px;
}

.language-btn {
	transition: all 0.2s ease-in-out;
}

.language-btn.unfocused {
	cursor: pointer;
	opacity: 0.5;
	letter-spacing: 1px;
}

.language-btn.unfocused:hover {
	color: var(--text-light);
	opacity: 0.5;
	letter-spacing: 4px;
}

.language-btn.focused {
	font-weight: bold;
	font-size: 18px;
	color: var(--text-light);
	cursor: default;
}

@media screen and (max-width:1475px) and (min-width: 960px) {
	.nav-item {
		width: 10vw;
		max-width: 130px;
		transition: all 0.3s ease-out;
	}
	
	.nav-links {
		letter-spacing: 1px;
		font-size: 13px;
		min-width: 0;
		transition: all 0.3s ease-out;
	}

	.nav-links-reservar {
		letter-spacing: 1px;
		font-size: 13px;
		transition: all 0.3s ease-out;
		min-width: 0;
		max-width: 130px;
	}

	.logo {
		height: 60px;
	}

	.logo-2 {
		height: 30px;
	}

	.navbar-language-changer {
		min-width: 0;
		max-width: 130px;
		letter-spacing: 2px;
		font-size: 13px;
	}
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.nav-item {
		height: 80px;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		/* height: 90vh; */
		position: absolute;
		top: 100px;
		left: -100%;
		opacity: 0;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: white;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
		justify-content: flex-start;
		box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 50%)
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.navbar-logos {
		width: 300px;
	}

	.logo {
		height: 60px;
	}

	.logo-2 {
		height: 25px;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-40%, 70%);
		font-size: 2rem;
		cursor: pointer;
	}
}

@keyframes navbarFadeIn {
    0% {
        transform: translateY(-100px)
    }

    100% {
        transform: translateY(0)
    }
}