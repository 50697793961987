.contactos-background-container {
    background-image : url("../../../assets/images/contacts/FUNDO_CONTACTOS.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    padding-top: 100px;
    height: fit-content;
    animation: contactosFadeIn 0.3s ease-in-out;
}

.contactos-block-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 170px);
    height: fit-content;
    width: 45%;
    max-width: 900px;
    min-width: 500px;
    margin-left: 10vw;
}

.contactos-title {
    float: left;
    text-align: left;
    color: rgb(214, 182, 38);
    font-size: 38px;
    font-weight: 100;
    line-height: 1.3;
    padding-left: 5vw;
    padding-top: 20px;
    width: 80%;
}

.contactos-text {
    font-size: 20px;
    color: rgb(82, 63, 39);
    font-weight: 100;
    line-height: 1.5;
    white-space: pre-line;
    padding: 20px 5vw 20px 5vw;
    text-align: left;
}

@keyframes contactosFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
  }

@media screen and (max-width: 960px) {

    .contactos-block-container {
        margin-left: 0;
        width: 100vw;
        min-width: 100vw;
    }

    .contactos-title {
        width: 100vw;
    }

}