.custom-rating-main-div {
    display: flex;
    flex-wrap: wrap;
}

.custom-rating-icon-div {
    cursor: pointer;
}

.custom-rating-icon-div.filled > svg {
    fill: #61634f
}

.custom-rating-icon-div > svg {
    fill: rgba(97, 99, 79, 0.5)
}

.custom-rating-icon-div:hover > svg {
    fill: #61634f;
    transform: scale(1.2);
}

.compact-rating-main-div {
    position: relative;
    width: 250px;
    border: 1px solid #00000026;
    border-radius: 10px;
}

.compact-rating-fill-bar {
    position: absolute;
    height: 20px;
    top: 0px;
    border-radius: 10px;
    background-color: var(--text-light);
    z-index: -1;
}

.compact-rating-text {
    text-align: center;
    color: var(--text-dark);
    z-index: 10;
}