.reservation-contact-confirmation-Dialog {
    
}

.reservation-contact-dialog-content {
    height: 850px;
    width: 750px;
    display: flex;
    padding: 0px 20px !important;
    flex-direction: column;
    justify-content: space-around;
}

.reservation-contact-dialog-upper {
    height: 20%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
}

.reservation-contact-dialog-title {
    float: left;
    text-align: left;
    color: rgb(214, 182, 38);
    font-size: 38px;
    font-weight: 100;
    line-height: 1.3;
    padding-left: 5vw;
    padding-top: 20px;
    width: 80%;
}

.reservation-contact-dialog-text {
    font-size: 18px;
    color: rgb(82, 63, 39);
    font-weight: 100;
    line-height: 1.5;
    white-space: pre-line;
    padding: 20px 5vw 20px 5vw;
    text-align: left;
}


@media screen and (max-width: 960px) {
    .reservation-contact-dialog-content {
        height: fit-content;
        width: 100%;
        display: flex;
        padding: 0px !important;
        flex-direction: column;
        justify-content: flex-start;
    }

    .reservation-contact-dialog-upper {
        height: fit-content;
    }
}