.date-main-container {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    position: relative;
}

.main-container-inner {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.right-side-div.show {
    position: relative;
    height: 100%;
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: all 0.3s ease-in-out;

}

.right-side-div.hide {
    position: relative;
    height: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

.left-side-div {
    width: 45%;
    height: 100%;
    position: relative;
    max-width: 750px;
}

.onTopText {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 26px;
    letter-spacing: 3px;
    color: white;
}

.left-side-text {
    position: absolute;
    top: 50px;
    height: 50px;
    line-height: 50px;
    width: 100%;
    background-color: white;
    text-align: center;
    
}

.left-side-title {
    font-size: 22px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
}

.left-side-subtitle {
    color: var(--text-dark);
    text-align: center;
}

.people-selection-div {
    height: fit-content;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7);
    margin-top: 30px;
    margin-bottom: -10px;
    padding: 20px;
    border-radius: 10px;
    min-width: 40%;
    max-width: 75%;
}

.people-selection {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dates-div {
    margin: 20px;
    padding: 20px;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.7); */
    border-radius: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 83%;
    width: fit-content;
    min-width: 100%;
}

.date-inner-div {
    width: 50%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dates-bottom-info {
    position: absolute;
    bottom: 5px;
    width: 95%;
    text-align: center;
    margin: 5px 0;
    font-size: 15px;
    font-style: italic;
    color: var(--text-dark);
}

.unavailable-combination-warning {
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 5px 0;
    font-size: 18px;
    color: var(--text-dark); 
    background-color: rgba(255,255,255,0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 20;
}

.calendar-bottom-info {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
    margin: 5px 0;
    font-size: 15px;
    font-style: italic;
    background-color: white;
    color: var(--text-dark);
}

.date-div-hours {
    height: 80%;
    overflow-y: auto ;
}

.checkIn-pick-hours-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    user-select: none;
    position: relative;
}

.checkOut-pick-hours-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    user-select: none;
    position: relative;
}

.hours-container-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hours-container-inner.disabled {
    cursor: default;
    opacity: 0.5;
}

.hours-container-inner.disabled > button {
    cursor: default;
}

.hours-container-inner.disabled:hover {
    background-color: white;
}

.hours-container-inner:hover {
    background-color: var(--text-light);
    border-radius: 10px;
}

.hours-container-inner.selected {
    background-color: var(--text-light);
    border-radius: 10px;
}

.hours-btn {
    width: 70px;
    height: 20px;
    margin-right: 20px;
    border: none;
    background-color: var(--text-light);
    color: white;
    font-size: 17px;
    border-radius: 10px;
    cursor: pointer;
}

.hours-btn:disabled {
    cursor: default;
    background-color: #f3f3f3;
    color: var(--text-dark)
}

.no-seats-p {
    position: absolute;
    width: 100%;
    background-color: rgba(255,255,255,0.5);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    z-index: 20;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-container-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 500;
    color: var(--text-dark);
}

/* .date-div-hours::-webkit-scrollbar {
    width: 10px;
    margin-left: 5px;
}

.date-div-hours::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
}

.date-div-hours::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: rgba(255,255,255,0.5);
    background-clip: content-box;
}

.date-div-hours::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: rgba(255,255,255,0.75);
} */

.unavailable-days-popup.show {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: white;
    color: var(--text-dark);
    font-weight: 500;
    font-size: 18px;
    bottom: 300px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 50px;
    border-radius: 20px;
    box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.5);
}

.unavailable-days-popup.hide {
    display: none;
}

.mobile-person-input {
    border: none;
    width: 200px;
    height: 30px;
    border-radius: 10px;
    color: var(--text-dark);
    text-indent: 10px;
}

.big-group-text {
    margin-top: 15px;
    font-size: 16px;
    color: var(--text-dark);
    font-style: italic;
}

.big-group-span {
    cursor: pointer;
    text-decoration: underline;
    color: cadetblue;
    margin-left: 5px;
}


@media screen and (max-width: 1260px) {

    .main-container-inner {
        flex-direction: column-reverse;
    }

    .vertical-divider {
        display: none;
    }

    .date-main-container {
        margin-top: 15px;
        height: fit-content;
    }


    .left-side-div {
        width: 100%;
        height: 565px;
    }

    .calendar-bottom-info {
        background-color: transparent;
    }
}

@media screen and (max-width: 960px) {

    .main-container-title {
        padding: 0 30px;
    }

    .people-selection-div {
        width: 100%;
        max-width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .people-selection {
        flex-direction: column;
    }

    .right-side-div.show {
        width: 100%;
    }

    .left-side-div {
        width: 100%;
        height: 525px;
    }

    .left-side-text {
        line-height: 20px;
    }

    .calendar-bottom-info {
        background-color: transparent;
    }

    .dates-div {
        flex-direction: column;
        width: 100%;
        padding: 10px 5px 40px 5px;
    }

    .date-inner-div {
        width: 100%;
        padding: 10px 5px;
    }

    .date-div-hours {
        height: 350px;
    }

    .big-group-text {
        padding: 10px;
        text-align: center;
    }
    
    
}