.contacts-form-container {
    margin: 0px 11% 25px 11%;
    min-height: 495px;
}

.form-container {
    height: inherit;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contacts-text-area {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    height: fit-content;
    max-height: 500px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
}

.contacts-text-area:focus,
.contacts-text-area:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.contacts-text-areaerror {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
    max-height: 500px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;;
    border: 1px solid rgb(170, 38, 38);
}

.contacts-text-areaerror:focus,
.contacts-text-areaerror:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.contacts-input {
    width: 100%;
    height: 40px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
}

.contacts-input:focus,
.contacts-input:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.contacts-label-title {
    padding-bottom: 8px;
    font-size: 17px;
    letter-spacing: 2px;
    color: rgb(97, 99, 79);
    padding-left: 15px;
}

.contacts-inputerror {
    width: 100%;
    height: 40px;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;;
    border: 1px solid rgb(170, 38, 38);
    border-right: 15px solid rgb(170, 38, 38);
}

.contacts-inputerror:focus,
.contacts-inputerror:hover {
    outline:none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.phone-email-fields {
    display: flex;
    justify-content: space-between;
}

.phone-email-fields > label {
    width: 48%;
}

.submitButton-Loading-div {
    width: 100%;
    display: flex;
}

.loading-symbol {
    z-index: 99;
    margin-left: -55px;
    height: 50px;
    width: 50px;
    fill:#ffffff;
    stroke: #ffffff;
    stroke-width: 2;
    stroke-opacity: 1
}

.submitButton-Loading-div > button {
    max-width: 300px;
    width:250px;
    height: 50px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: start;
    text-indent: 15px;
    font-size: 17px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.submitButton-Loading-div > button:disabled {
    background-color: grey;
    cursor:default;
}

.submitButton-Loading-div > button:disabled:hover {
    transform: scale(1);
    font-size: 17px;
}

.submitButton-Loading-div > button:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-out;
    font-size: 19px;
}

@media screen and (max-width: 960px) {

    .contacts-form-container {
        margin: -5px 35px 10px 35px;
    }

    .loading-symbol {
        margin-left: -60px;
    }

    .submitButton-Loading-div > button {
        text-align: center;
        text-indent: 0;
        width: 100%;
    }

    .submitButton-Loading-div {
        justify-content: center;
    }

}
