.background-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup {
    position: relative;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    width: 700px;
    height: 600px;
}

.pick-hours-popup-title {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pick-hours-popup-title > p {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    color: rgb(97, 99, 79); 
}

.hours-main-container {
    width: 100%;
    height: 70%;
    display: flex;
}

.checkin-container,
.checkout-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.hours-container {
    text-align: center;
}


.hours-picker-buttons {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.hours-picker-buttons > button {
    width: 200px;
    height: 40px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: center;
    font-size: 17px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.hours-picker-buttons > button:hover {
    width: 210px;
    transition: all 0.2s ease-out;
}

.css-1snvurg-MuiPickerStaticWrapper-root {
    background-color: transparent !important;
}

.css-417j15 {
    background-color: transparent !important;
}

.top-info {
    height: 70px;
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 70px;
  }

  .top-info > p {
    font-size: 16px;
    color:#61634F;
  }

  .de-ate-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
  }

  .de-div {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  .ate-div {
    display: flex;
    flex-direction: row;
  }

  .labels {
    margin-right: 10px;
    font-weight: bold;
    font-size: 18px;
    align-self: center;
    color: #61634F;
    width: 100px;
  }

  .date-values {
    height: 30px;
    width: 300px;
    background: #DED497;
    line-height: 30px;
    font-size: 18px;
    color: #61634F;
  }

  .css-7lip4c, .css-a1rc6s, .css-12ha4i7 {
    background-color: #C8B852 !important;
  }

  .css-118whkv {
    border: 16px solid #C8B852 !important;
    background-color: #C8B852 !important;
  }

@media screen and (max-width: 960px) {

    .pick-hours-divider {
        display: none;
    }

    .background-div {
        height: fit-content;
    }

    .popup {
        width: 110vw !important;
        border-radius: 0;
        overflow-y: scroll;
        padding-bottom: 65px;
    }

    .popup-title {
        font-size: 26px;
    }

    .hours-main-container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    .checkin-container,
    .checkout-container {
        width: 100%;
    }

    .hours-picker-buttons {
        margin-top: 25px;
    }
    
}