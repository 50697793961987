.top-info-bar-main-div.show {
    position: fixed;
    top: 0;
    opacity: 1;
    min-height: 100px;
    height: fit-content;
    max-height: 100px;
    z-index: 901;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 20px 0px rgb(0 0 0 / 50%);
    transition: all 0.5s ease-in-out;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
    overflow-y: hidden;
}

.top-info-bar-main-div {
    opacity: 0;
    height: 0;
    padding: 0 25px;
    transition: all 0.5s ease-in-out;
}

.top-info-bar-main-div.show:hover {
    max-height: 500px;
}

.top-info-bar-main-div.show:hover > .top-info-more-text {
    opacity: 0;
    pointer-events: none;
}

.top-info-dates-div {
    display: flex;
}

.top-info-dates-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 40px;
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
}

.top-info-more-text {
    position: absolute;
    bottom: 5px;
    color: #61634f;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.top-info-dates-wrapper::-webkit-scrollbar {
    width: 10px;
}

.top-info-dates-wrapper::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 20px;
}

.top-info-dates-wrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: #61634f;
    background-clip: content-box;
}

.top-info-dates-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #d2bb53;
}

@media screen and (max-width: 960px) {
    .top-info-bar-main-div.show {
        height: fit-content;
        padding: 10px 0;
        flex-direction: column;
    }

    .top-info-bar-main-div.show>div {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .top-info-bar-main-div>div {
        opacity: 0;
        height: 0;
    }
}