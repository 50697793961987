.background-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.popup {
    position: relative;
    background-color: #F5F5F2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10%;
}

.popup-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-title > p {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 3px;
    color: rgb(97, 99, 79); 
}

.popup-content {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

}

.popup-buttons {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

}

@media screen and (max-width: 960px) {

    .popup {
        height: 110vh !important;
        width: 110vw !important;
        border-radius: 0;
    }

    .popup-title {
        font-size: 26px;
    }

}