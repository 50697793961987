.home-slider-container {
    width: 100vw;
    height: 100%;
}

.outter-container {
    height: calc(100vh - 70px);
    padding-top: 100px;
    animation: homeFadeIn 0.5s ease-in-out;
}

@media screen and (max-width: 960px) {

    /* .home-slider-container {
        height:65vh
    } */

    .outter-container {
        height: 100vh;
    }
}

@keyframes homeFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}