.vr360-view-background-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.5s ease-out;
}

.vr360-view-popup {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;
}

.vr360-view-navigation-div {
    width: 100%;
    height: 7%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.vr360-view-navigation-div > p {
    padding-left: 30px;
    color: rgb(97, 99, 79);
    font-size: 28px;
    font-weight: 200;
    line-height: 1.3;
    letter-spacing: 2px;
}

.vr360-view-btn-div {
    display: flex;
    width: 20%;
    height: 85%;
    justify-content:flex-end;
    align-items: center;
}

.vr360-view-btn-div > * {
    cursor: pointer;
    margin-right: 30px;
}

.iframe-div {
    width: 100%;
    height: 93%;
   
}

iframe {
    border-width: 0px;
}

.fa-window-close {
    color: rgb(97, 99, 79);
    font-size: 2.5em;
}

.fa-window-restore {
    color: rgb(97, 99, 79);
    font-size: 2em;
}


