.reservation-dialog-content {
    height: 400px;
    width: 700px;
    display: flex;
    padding: 0px 40px !important;
    flex-direction: column;
}

.reservation-dialog-content-top {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reservation-dialog-content-top > p {
    font-size: 22px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
    margin: 5px;
}

.reservation-dialog-content-middle {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reservation-dialog-content-bottom {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-dialog-btn {
    width: 200px;
    height: 40%;
    border: none;
    outline: none;
    background-color: var(--text-light);
    color: white;
    border-radius: 20px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.confirmation-dialog-btn:hover {
    width: 215px;
    height: 40%;
    border: none;
    outline: none;
    background-color: var(--text-light);
    color: white;
    border-radius: 10px;
}

.confirmation-dialog-btn.hidden {
    height: 0;
    opacity: 0;
}

.round-background {
    height: 100%;
    width: 100%;
    background-color: var(--text-light);
    border-radius: 50%;
}

.confirmation-dialog-loading-svg {
    position: absolute;
    height: 75%;
    width: 75%;
}

.confirmation-dialog-loading-div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
}

.confirmation-dialog-loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.confirmation-dialog-loading-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    height: 100%;
}

.confirmation-dialog-state-text {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 300;
    color: var(--text-dark);
    white-space: pre-wrap;
}

@media screen and (max-width: 960px) {

    .reservation-dialog-content {
        width: 100%;
        height: 100%;
        padding: 0 10px !important
    }

    .reservation-dialog-content-middle {
        flex-direction: column;
        margin: 10px;
    }

    .confirmation-dialog-loading-info {
        width: 100%;
    }

    .confirmation-dialog-loading-icon {
        width: 100%;
        margin: 25px;
    }

    .reservation-dialog-content-bottom {
        height: 10%;
    }

    .confirmation-dialog-state-text {
        text-align: center;
    }

    .reservation-dialog-content-top > p {
        text-align: center;
    }
}