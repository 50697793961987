.checkIn-arrow-top {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.checkIn-arrow-top.show {
    opacity: 1;
}


.checkIn-arrow-down {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.checkIn-arrow-down.show {
    opacity: 1;
}

.checkOut-arrow-top {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.checkOut-arrow-top.show {
    opacity: 1;
}


.checkOut-arrow-down {
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.checkOut-arrow-down.show {
    opacity: 1;
}