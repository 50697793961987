.reservation-review-div {
    width: 45%;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.review-info-title-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.review-title {
    font-size: 22px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
    margin: 5px;
}

.review-subtitle {
    max-width: 500px;
    text-align: center;
    color: var(--text-dark);
}

.reservation-info-review-div {
    width: 100%;
    padding: 15px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reservation-review-action {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.review-submit-btn-div {
    display: flex;
}

.review-submit-btn {
    width: 80%;
    height: 50px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    text-transform: uppercase;
}

.review-submit-btn:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-out;
}

.review-submit-btn:disabled {
    background-color: grey;
    cursor: default;
}

.review-submit-btn:disabled:hover {
    transform: scale(1);
}

.review-labels-div {
    width: 100%;
    margin-bottom: 15px;
}

.review-labels-title {
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
}

.review-labels-text {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 300;
    color: var(--text-dark);
}

.review-tnc-label {
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
    color: var(--text-dark);
    margin-left: 5px;
}

.reservation-checkbox-div {
    margin-bottom: 5px;
}

.reservation-review-info {
    padding-bottom: 25px;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.review-multiple-div {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.review-multiple-labels {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    border-bottom: 1px solid var(--text-dark);
}

.review-multiple-labels > p {
    margin-right: auto;
    width: 150px;
    text-align: center;
}

.review-multiple-values {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--text-dark);
    margin: 5px 0;
    align-items: center;
}

.review-labels-text-multiple {
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 300;
    color: var(--text-dark);
    width: 150px;
    text-align: center;
    margin-right: auto;
}

.reservation-share-radio {
    color: var(--text-dark);
}

.reservation-share-radio .Mui-checked {
    color: var(--text-light) !important;
}

@media screen and (max-width: 960px) {
    .reservation-review-div {
        width: 100%;
        margin-top: 40px;
    }

    .review-labels-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .review-info-title-div {
        margin-bottom: 10px;
    }

    .review-multiple-div {
        margin-bottom: 20px;
    }

    .review-labels-text-multiple {
        font-size: 12px;
        letter-spacing: 1px;
    }
}