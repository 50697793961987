.feedback-background-container {
    background-image : url("../../../assets/images/contacts/FUNDO_CONTACTOS.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    display: flex;
}

.feedback-block-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 45%;
    max-width: 900px;
    min-width: 500px;
    margin-left: 10vw;
}

.feedback-title {
    float: left;
    text-align: left;
    color: rgb(214, 182, 38);
    font-size: 38px;
    font-weight: 100;
    line-height: 1.3;
    padding-left: 5vw;
    padding-top: 20px;
    width: 80%;
}

.feedback-text {
    font-size: 20px;
    color: rgb(82, 63, 39);
    font-weight: 100;
    line-height: 1.5;
    white-space: pre-line;
    padding: 20px 5vw 20px 5vw;
    text-align: left;
}

.feedback-already-filled {
    height: fit-content;
}

.feedback-languageSelector {
    width: 100px;
    height: 100px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.flagDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: opacity(0.5)
}

.flagDiv-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: opacity(1)
}

.flagDiv:hover {
    filter: opacity(1);
    cursor: pointer;
}

@media screen and (max-width: 960px) {

    .feedback-background-container {
        flex-direction: column-reverse;
    }

    .feedback-languageSelector {
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .feedback-block-container {
        margin-left: 0;
        width: 100vw;
        min-width: 100vw;
    }

    .feedback-title {
        width: 100vw;
    }

    .feedback-phone-email-fields {
        flex-direction: column;
    }

    .feedback-phone-email-fields > label {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .feedback-phone-email-fields {
        margin-bottom: 5px !important;
    }
}