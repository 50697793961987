.background-container-parceiros {
    background-repeat: no-repeat;
    background-size: cover;
    background-position:left;
    width: 100vw;
	  height: 110vh;
    display: flex;
    justify-content: flex-end;
    padding-top: 100px;
}
 
.block-container-parceiros {
	  display: flex;
	  flex-flow: column;
   	min-height: inherit;
    margin-right: 15vw;
    width: 850px;
    background-color: white;
}

.person-name, .person-profession {
	  float: left;
	  text-align: left;
    color: rgb(214, 182, 38);
    font-size: 38px;
    line-height: 1.3;
	  padding-left: 5vw;
	  letter-spacing: 3px;
}

.person-name {
    padding-top: 50px;
    font-weight: bold;
}

.person-profession {
	  font-weight: 300;
}

.person-location {
    line-height: 1.3;
    padding-left: 5vw;
	  padding-bottom: 50px;
    color: rgb(82, 63, 39);
	  font-weight: 200;
}

  .person-description {
	  font-size: 18px;
    color: rgb(82, 63, 39);
    font-weight: 100;
    line-height: 1.5;
	  padding-right: 5vw;
	  padding-left: 5vw;
	  padding-top: 20px;
	  text-align: left;
    white-space: pre-line;
  }

  .person-webpage {
	margin-top: 100px;
	line-height: 1.3;
	padding-left: 5vw;
	padding-bottom: 50px;
	color: rgb(82, 63, 39);
	font-weight: 200;
  }

  @media screen and (max-width: 1020px) {

	.background-container-parceiros {
	  background-size: 100vh;
    height: fit-content;
    justify-content: center;
	}

  .block-container-parceiros {
    height: fit-content;
    margin-right: 0;
    width: 100vw;
	}

  .mobile-picture {
    height: 350px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position:top;
  }

  .person-location {
    padding-bottom: 20px;
  }
}