.loja-details-container {
    min-height: 75vh;
    padding-top: 100px;
    /* border: 1px solid red; */
}

.loja-details-upper {
    /* border: 1px solid black; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    margin: 50px;
}

.loja-details-title {
    color: rgb(214, 182, 38);
    font-size: 32px;
    font-weight: 100;
    letter-spacing: 4px;
    margin-bottom: 15px;
}

.loja-details-text {
    width: 52%;
    font-size: 17px;
    color: rgb(82, 63, 39);
    font-weight: 200;
    line-height: 1.6;
    white-space: pre-line;
}

.loja-details-bottom {
    /* border: 1px solid darkmagenta; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 15px;
}

.item-sec-background {
    height: 100%;
    width: 100%;
    position: relative;
}

.loja-individual-card {
    margin: 15px;
}

.item-card:hover {
    transform: scale(1.03);
    border-radius: 5%;
    z-index: 0;
    transition: all 0.4s ease-out;
    cursor: pointer;
}

.item-card:hover .item-sec-background {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5%;
    transition: all 0.4s ease-out;
} 

.item-card:hover .item-card-info {
    z-index: 1;
}

.item-card-info {
    color: rgb(255, 255, 255);
    text-align: center;
    z-index: -1;
    position: relative;
    top: -190px;
    width: 200px;
    margin-left: 16%;
}

.item-card-info > p {
    padding-bottom: 5px;
    letter-spacing: 2px;
    font-weight: 300;
}

.item-price {
    font-size: 24px;
}

.loja-image-loading-icon {
    height: 70px;
    width: 70px;
    fill:#B39E2D;
    stroke: #B39E2D;
    stroke-width: 2;
    stroke-opacity: 1
}

@media screen and (max-width: 960px) {

    .loja-details-text {
        width: 100%;
    }

    .item-sec-background {
        height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 100%);
        border-radius: 5%;
        z-index: 0;
    }

    .item-card-info {
        z-index: 1;
        top: -145px
    }

    .item-card {
        border-radius: 5%;
    }
    
    .item-card:hover {
        transform: scale(1.03);
        transition: all 0.4s ease-out;
    }

    .item-card:hover .item-sec-background {
        background-color: rgba(255, 255, 255, 0);
        border-radius: 5%;
        transition: all 0.4s ease-out;
    } 
}