.info-main-container {
    height: fit-content;
    min-height: 300px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 20px;
    padding: 20px 20px 20px 20px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 70%);
}

.plant-map-div {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.cowork-properties-div {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;
    height: fit-content;
    width: 100%;
    padding: 30px;
}

.cowork-map-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    max-width: 800px;
    height: 400px;
    position: relative;
}

.map-test {
    height: 250px;
    width: auto;
}

.info-map-label {
    color: var(--text-dark);
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
}

.amenities-div {
    margin: 10px 10px;
    display: flex;
}


.amenities-group-title {
    font-size: 16px;
    letter-spacing: 3px;
    font-weight: 500;
    color: var(--text-dark);
}

.amenities-group-inner-div {
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
}

.cowork-plant {
    height: 371px;
    z-index: 20;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    position: relative
}

.plant-zoom-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    filter: drop-shadow(3px 2px 7px black);
    transition: all 0.5s ease-in-out;
}

.plant-zoom-icon:hover {
    transform: scale(1.1);
}


.cowork-plant.zoom {
    transform: scale(1.8);
    transform-origin: 20px;
    z-index: 999;
}

.cowork-plant-img {
    border-radius: 20px;
    height: 100%;
    width: auto;
}

.info-container-divider {
    width: 90%;
    margin: 20px 0 !important;
}

.info-main-map-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-dark);
    border-radius: 20px;
    border: 2px solid var(--text-light);
    animation: mapLoading 3s ease-in-out infinite
}

@keyframes mapLoading {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media screen and (max-width: 1260px) {
    .info-main-container {
        margin: 25px;
        padding: 0;
        box-shadow: none;
    }

    .cowork-plant:hover {
        transform: scale(1);
        transform-origin: 0%
    }

    .cowork-properties-div {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 960px) {

    .info-main-container {
        margin: 25px 0;
    }

    .cowork-properties-div {
        grid-template-columns: auto;
    }

    .amenities-group-div {
        display: flex;
        flex-direction: column;
        margin: 15px 0;
        align-items: center;
    }

    .plant-map-div {
        flex-direction: column;
        align-items: center;
    }

    .cowork-plant-img {
        width: 100%;
        max-width: 500px;
        height: auto;
    }

    .cowork-map-div {
        margin-top: 25px;
        width: 100%;
    }

    .amenities-group-inner-div {
        flex-direction: column;
        align-items: center;
    }

    .plant-zoom-icon {
        opacity: 0;
        pointer-events: none;
    }
}