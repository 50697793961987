.item-details-container {
    display: flex;
    height: 85vh;
    min-height: 600px;
}

.item-details-left-container {
    width: 60%;
    display: flex;
    margin: 100px 25px 100px 50px;
}

.item-details-right-container {
    width: 40%;
    height: 100%;
}

.item-details-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2% 2% 2% 4%;
}

.item-details-condition {
    width: 50%;
    margin: 7% 4% 4% 2%;
}

.item-details-title {
    font-size: 22px;
    font-weight: bold;
    color: #C8B852;
}

.item-details-subtitle {
    font-weight: bold;
    font-size: 18px;
    color: #61634F;
}

.item-details-text {
    font-size: 24px;
    font-weight: 300;
    color: #61634F;
}

.item-details-condition-text {
    color: #61634F;
    font-weight: 200;
    font-size: 17px;
    line-height: 26px;
    white-space: pre-line;
}

.item-details-condition > p {
    margin-bottom: 30px;
    white-space: pre-line;
}

.item-details-right-container > img {
    width: 100%;
    height: auto;
}

.item-details-info > button {
    max-width: 300px;
    height: 50px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: start;
    text-indent: 15px;
    background-color: #C8B852;
    color: white;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
}

.item-details-info > button:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-out;
    font-size: 19px;
}

.item-details-info > button:disabled {
    background-color: #616351;
    cursor: default;
}

@media screen and (max-width: 960px) {
    
    .item-details-container {
        flex-direction: column-reverse;
        justify-content: center;
        height: fit-content;
    }

    .item-details-left-container {
        width: 100%;
        margin: 0;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .item-details-right-container {
        width: 100%;
        margin: 30px 0 30px 0;
    }

    .item-details-info {
        width: 100%;
    }

    .item-details-condition {
        width: 90%;
        margin: 0 10px 30px 10px;
    }

    .item-details-subtitle {
        margin-bottom: 5px;
    }

    .item-details-condition > p {
        margin-bottom: 15px;
    }

    .item-details-info {
        width: 90%;
        height: 600px;
        margin: 0;
        
    }
}