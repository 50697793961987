.main-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-height: calc(100vh - 170px);
    width: 100vw;
    overflow-x: hidden;
    padding-top: 100px;
    animation: espaçosDetalhesFadeIn 0.3s ease-in-out;
}

.upper-container {
    background-image: url("../../../assets/images/espaços/EspaçosBackground.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 550px;
    height: fit-content;
}

.slider-container {
    width: 50vw;
    height: auto;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    width: 50vw;
    height: fit-content;
    /* min-height: 700px; */
    /* border: 1px solid goldenrod; */
}

.info-title {
    float: left;
    text-align: left;
    color: rgb(210, 187, 83);
    font-size: 38px;
    font-weight: 100;
    line-height: 1.3;
    padding-left: 2vw;
    padding-top: 3vh;
    letter-spacing: 2px;
    height: 20%;
    margin: 50px 50px 15px 50px;

}

.info-description {
    font-size: 16px;
    color: rgb(104, 109, 107);
    font-weight: 300;
    line-height: 1.3;
    padding-right: 5vw;
    padding-left: 2vw;
    padding-top: 10px;
    text-align: left;
    height: 60%;
    margin: 15px 50px 15px 50px;
    white-space: pre-line;
}

.info-buttons {
    display: flex;
    height: 20%;
    justify-content: left;
    align-content: center;
    margin: 0px 30px 25px 30px;
    min-height: 75px;
}

.info-buttons>*:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-out;
}

.book-button {
    background-image: url("../../../assets/images/espaços/expandable/ÍCONES-59-light.png");
}

.vr-visit-button {
    background-image: url("../../../assets/images/espaços/expandable/ÍCONES-60-light.png");
}

.info-buttons>* {
    width: 25%;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0px 15px 10px 50px;
    max-width: 75px;
    max-height: auto;
    min-width: 50px;
    min-height: auto;
}

.booking-container {
    height: 100%;
    min-height: 700px;
    padding: 0px 75px 0px 75px;
    display: flex;
    flex-direction: column;
}

.booking-title-div {
    height: 15%;
    width: 100%;
    margin-left: 25px;
}

.booking-title {
    color: rgb(97, 99, 79);
    font-size: 34px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 3px;
    margin-top: 25px;
}

.booking-subtitle {
    color: rgb(97, 99, 79);
    font-size: 24px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 3px;
    margin-bottom: 25px;
}

.booking-bottom {
    display: flex;
    justify-content: space-around;
    height: fit-content;
    min-height: 700px;
}

.booking-dropdown {
    width: 25%;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.form-wrapper>form {
    width: 100%;
    height: 100%;
    margin-left: 8%;
}

.book-submit-button {
    width: 200px;
    height: 50px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: start;
    text-indent: 15px;
    font-size: 17px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.book-submit-button:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-out;
    font-size: 18px;
}

.book-submit-button:disabled {
    background-color: grey;
    cursor: default;
}

.book-submit-button:disabled:hover {
    transform: scale(1);
    font-size: 17px;
}

.form-input-fields {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
}

.form-input-fields>* {
    margin-bottom: 25px;
}

.submitButton-Loading-div-espaços {
    width: 100%;
    display: flex;
}

.loading-symbol-espaços {
    z-index: 99;
    margin: 5px;
    height: 50px;
    width: 50px;
    fill: #C8B852;
    stroke: #C8B852;
    stroke-width: 2;
    stroke-opacity: 1;
}

.input {
    width: 100%;
    height: 40px;
    border: none;
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
}

.inputerror {
    width: 100%;
    height: 40px;
    border: 1px solid rgb(170, 38, 38);
    border-right: 20px solid rgb(170, 38, 38);
    background: #DFE0DC;
    color: #61634F;
    font-size: 16px;
    text-indent: 15px;
}

.inputerror:focus,
.inputerror:hover {
    outline: none;
    background-color: #cecfc9;
    transition: all 0.2s ease-out;
}

.input:focus,
input:hover {
    outline: none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

select:hover,
select:focus {
    outline: none;
    background-color: #cecfc9;
    transition: all 0.5s ease-out;
}

.label-title {
    padding-bottom: 8px;
    font-size: 17px;
    letter-spacing: 2px;
    color: rgb(97, 99, 79);
}

.booking-calendar-main {
    display: flex;
    flex-direction: column;
    /* border: 1px solid green; */
    width: 35%;
}

.booking-date {
    height: 15%;
}

.booking-calendar {
    display: flex;
    flex-direction: column;
    /* background-image: url("../../../assets/images/espaçosdetalhes/Image\ 1.png"); */
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    min-width: 400px;
}

.booking-plant {
    margin-top: 25px;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    width: 35%;
}

.popup-buttons>button {
    width: 150px;
    height: 40px;
    text-decoration: none;
    outline: none;
    border: none;
    text-align: center;
    font-size: 17px;
    background-color: #C8B852;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 15px;
}

.close-btn:hover,
.submit-btn:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-out;
    font-size: 18px;
}

.close-btn:disabled,
.submit-btn:disabled {
    background-color: grey;
    cursor: default;
}

.close-btn:disabled:hover,
.submit-btn:disabled:hover {
    transform: scale(1);
    font-size: 17px;
}

.popup-content-lable {
    font-weight: bold;
    font-size: 18px;
    align-self: center;
    color: #61634F;
    margin-right: 20px;
    width: 40%;
}

.popup-content-value {
    height: 30px;
    width: 100%;
    background-color: #DED497;
    line-height: 30px;
    font-size: 18px;
    color: #61634F;
    text-indent: 20px;
}

.popup-content-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.popup-content-text {
    font-size: 20px;
    color: rgb(82, 63, 39);
    font-weight: 100;
    line-height: 1.5;
    white-space: pre-line;
    text-align: center;
}

@media screen and (max-width: 960px) {

    .main-container {
        width: 100vw;
        /* height: auto; */
        overflow-y: hidden;
        min-height: fit-content;
        overflow-x: hidden;
    }

    .upper-container {
        flex-direction: column;
        width: 100%;
        height: fit-content;
    }

    .info-container {
        width: 100%;
        min-height: min-content;
    }

    .info-title {
        margin: 30px 30px 10px 30px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
        padding: 0;
    }

    .info-description {
        margin: 10px 30px 10px 30px;
        padding: 0;
    }

    .info-buttons {
        justify-content: space-evenly;
        margin: 15px 0;
    }

    .info-buttons>* {
        margin: 0
    }

    .slider-container {
        width: 100%;
        height: 300px;
    }

    .booking-container {
        padding: 15px 10px 15px 10px;

    }

    .booking-title {
        justify-content: center;
    }

    .booking-bottom {
        flex-direction: column-reverse;
        align-items: center;
    }

    .booking-plant {
        width: 400px;
        height: 400px;
        margin-top: 10px;
        margin-bottom: 75px;
    }

    .booking-calendar {
        margin-top: 25vw;
        width: 100%;
        /* height: 450px; */
        background-image: none;
        /* background-size: 120%; */
        min-width: 200px;
    }

    .top-info {
        width: 100%;
        margin-bottom: 70px;
        margin-top: -25vw;
    }

    .de-ate-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .de-div,
    .ate-div {
        display: flex;
        flex-direction: column;
    }

    .de-div {
        margin-bottom: 10px;
    }

    .DayPicker-Day {
        font-size: 15px;
        padding: 0.8em 1em 0.8em 1em;
    }

    .DayPicker-Caption>div {
        margin: 0;
        font-size: 32px;
    }

    .DayPicker-NavButton--next {
        margin-right: -10px;
    }

    .DayPicker-NavButton--prev {
        margin-left: -10px;
    }

    .booking-dropdown {
        width: 100%;
    }

    .form-wrapper>form {
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-input-fields {
        margin-bottom: 20px;

    }

    .form-input-fields>label {
        margin-bottom: 15px;
    }

    .book-submit-button {
        text-align: center;
        text-indent: 0;
        width: 80%;
    }

    .submitButton-Loading-div-espaços {
        justify-content: center;
    }

    .book-submit-button:disabled {
        width: 80%;
    }

}

@keyframes espaçosDetalhesFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}