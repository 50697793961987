.main-container-div {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: mainContainer 0.3s ease-in-out;
}

.form-review-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 50px;
    margin: 25px;
}

.form-review-container.hide {
    display: none;
}

.add-dates-div {
    margin: 20px;
    z-index: 100;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 50%);
    transition: all 0.2s ease-in-out;
}

.add-dates-div:hover {
    padding: 10px 20px;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 50%);
}

.date-container-wrapper {
    width: 95%;
    border-radius: 20px;
    box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 70%);
    margin-bottom: 20px;
    position: relative;
}

.date-container-wrapper>svg {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--text-dark);
    cursor: pointer;
    z-index: 10;
}

.date-container-top {
    height: 40px;
    background-color: var(--text-light);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    gap: 5px;
    padding: 0 20px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
}

.date-container-delete {
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.date-container-delete:hover {
    letter-spacing: 1px;
}

@media screen and (max-width: 960px) {
    .form-review-container {
        padding: 0px;
        flex-direction: column;
        margin: 25px 0;
    }

    .date-container-wrapper {
        width: 100%;
        border: none;
        border-radius: 20px;
        box-shadow: 2px 2px 5px 0px rgb(0 0 0 / 70%);
        margin-bottom: 40px;
        position: relative;
    }

}

@keyframes mainContainer {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}